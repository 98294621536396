.delighter{
    transition: all .3s ease-out;
    opacity: 0;
    &.started{
        opacity: 1;
    }
    &.ended{
    }

    //fadein vertical
    &.fadein-v,&.fadein-v--reverse{
        transform: translate(0,30px);
        &.started{
            transform: translate(0, 0);
        }
    }

    &.fadein-h--reverse{
        transform: translate(0,-30px);
    }

    //fadein horizontal
    &.fadein-h,&.fadein-h--reverse{
        transform: translate(30px,0);
        &.started{
            transform: translate(0, 0);
        }
    }

    &.fadein-h--reverse{
        transform: translate(-30px,0);
    }
}