@import "library/_animation";
@import "library/_colors";
@import "library/_delighters";
@import "library/_fonts";
@import "library/_mixin";
@import "library/_typo";
/*
 * COVER
 */
.cover{
    height: 100%;
    position: relative;
}
.cover__title{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: _rem(186px, md);
    height: _rem(186px, md);
    z-index: 2;
}
.cover__bg{
    height: 100%;
    width: 100%;
    div{
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
    }
}

.swiper-pagination{
    bottom: _rem(30px, md);
    width: 100%;
}
.swiper-pagination-bullet{
    width: _rem(14px, md);
    height: _rem(14px, md);
    border: 1px solid $color-white;
    background-color: transparent;
    opacity: .8;
    margin: 0 _rem(15px, md);
    outline : none;
}
.swiper-pagination-bullet-active{
    background-color: $color-white;
}

/*
 * INTRO
 */
.intro{
    background-color: oldlace;
    @extend %blockpadding;
    @include mq(sm,min){
        padding-bottom: _rem(60px, md);
    }
}
.intro__title{
    span{
        display: inline-block;
        padding: 0 0 1rem;
        border-bottom: 3px solid $color-black;
        margin-bottom: .5rem;
    }
}
.intro__subtitle{
    font-size: _rem(12px, sm);
    font-family: source-han-sans-japanese,sans-serif;
    font-weight: 300;
    @include mq(sm, min){
        font-size: _rem(13px, md);
    }
}
.intro__text{
    margin: .8rem 0;
    padding: _rem(15px, sm);
    color: #6a6565;
    //white-space: nowrap;
    text-align: left;
    display: inline-block;
}
/*
 * EVENT
 */
.event{
    background:linear-gradient(0deg,$color-black-two 0%,$color-black-two 70%,oldlace 70%,oldlace 100%);
    @include mq(lg, min){
        background:linear-gradient(0deg,$color-black-two 0%,$color-black-two 40%,oldlace 40%,oldlace 100%);
    }
}
.event__inner{
    color: $color-white;
    padding: _rem(85px, md) 0;
    background-repeat: no-repeat;
    background-image: url(../img/top/event-bg.jpg);
    background-size: contain;
    margin: 0 _rem(15px, sm);
    @include mq(lg, min){
        background-size: cover;
        width: 100%;
        max-width: 1200px;
        margin: auto;
    }
}
.event__title{
    color: $color-white;
    span{
        display: inline-block;
        padding: 0 .5rem 1rem;
        border-bottom: 3px solid $color-white;
    }
}
.event__text{
    margin: _rem(30px, md) 0;
    @include mq(sm,min){
        font-size: _rem(18px, md);
    }

}
.btn-area{
    display: inline-block;
    margin: auto;
}
.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $color-white;
    color: $color-white;
    position: relative;
    border-radius: _rem(65px, md);
    width: _rem(340px, md);
    height: _rem(65px, md);
    &:after{
        content: getArw(white);
        position: absolute;
        right: _rem(32.5px, md);
        width: 20px;
        display: inline-block;
        transform: scale(.35) rotate(90deg);
        transform-origin: 100%;
    }
    &:hover{
        background-color: rgba($color-black, .5);
    }
}
/*
 * MENU
 */
.menu{
    @extend %blockpadding;
    padding-bottom: 0;
}
.menu__inner{
    @extend %innerblock;
    max-width: _rem(1320px, md);
}
.menu__title{
     span{
        display: inline-block;
        padding: 0 1rem 1rem;
        border-bottom: 3px solid $color-black-three;
     }
}
.menu__subtitle{
    font-size: _rem(14px, sm);
    margin-top: _rem(30px, sm);
    margin-bottom: _rem(20px, sm);
    @include mq(sm,min){
        font-size: _rem(22px ,md);
    }
}
.menu__block{
    padding-bottom: _rem(40px, md);
}
.menu__block--chohu{
    background:linear-gradient(180deg,$color-white 0%,$color-white _rem(58px, sm),$color-aliceblue _rem(58px, sm),$color-aliceblue _rem(238px, sm),$color-white _rem(238px, sm),$color-white 100%);
    margin-left: _rem(15px, sm);
    @include mq(sm, min){
        background:linear-gradient(0deg,$color-aliceblue 0%,$color-aliceblue _rem(180px, md),$color-white _rem(180px, md),$color-white 100%);
        margin-left: 0;
    }
}
.menu__block--shinurayasu{
    background:linear-gradient(180deg,$color-white 0%,$color-white _rem(58px, sm),$color-mediumseagreen _rem(58px, sm), $color-mediumseagreen _rem(238px, sm),$color-white _rem(238px, sm),$color-white 100%);
    margin-right: _rem(15px, sm);
    @include mq(sm, min){
        background:linear-gradient(0deg,$color-mediumseagreen 0%,$color-mediumseagreen _rem(170px, md),$color-white _rem(170px, md),$color-white 100%);
        margin-right: 0;
    }
}
.menu__list{
    display: flex;
    flex-direction: column;
    @include mq(sm, min){
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
    }
}
.menu__item{
    margin: 0 _rem(34px, md);
    @include mq(sm, max){
        width: _rem(150px, sm);
        margin: 0;
    }
    &:nth-child(1){
        transition-delay: 250ms;
    }
    &:nth-child(2){
        transition-delay: 500ms;
    }
    &:nth-child(3){
        transition-delay: 750ms;
    }
    &:nth-child(4){
        transition-delay: 1000ms;
    }
    &:nth-child(5){
        transition-delay: 1250ms;
    }
    span, img{
        @extend %animation;
        vertical-align: top;
    }
    img{
        width: 100%;
    }
    a{
        display: block;
        &:hover{
            background-color: $color-white;
            img{
                opacity: .4;
            }
            span{
                transform: translate(-50%, -50%) scale(1.1, 1.1);
            }
        }
    }
}
@include mq(sm, max){
    .menu__item--chohu01{
        margin-left: _rem(15px, sm);
    }
    .menu__item--chohu02{
        align-self: flex-end;
        margin-right: _rem(15px, sm);
        margin-top: _rem(-60px, sm);
    }
    .menu__item--chohu03{
        align-self: center;
        margin-left: _rem(-150px, sm);
        width: _rem(106px, sm);
    }
    .menu__item--chohu04{
        align-self: flex-end;
        width: _rem(106px, sm);
        margin: _rem(-120px, sm) _rem(50px, sm) 0 auto;
    }
    .menu__item--shinurayasu01{
        align-self: flex-end;
        margin-right: _rem(15px, sm);
    }
    .menu__item--shinurayasu02{
        margin-left: _rem(22.5px, sm);
        margin-top: _rem(-60px, sm);
    }
    .menu__item--shinurayasu03{
        align-self: flex-end;
        margin-top: _rem(-9px, sm);
        margin-right: _rem(15px, sm);
    }
    .menu__item--shinurayasu04{
        align-self: flex-end;
        margin-top: _rem(-9px, sm);
        margin-right: _rem(15px, sm);
    }
}
.menu__label{
    background-color: $color-black-two;
    color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: _rem(14px, md);
    padding: _rem(10px, md) _rem(20px, md);
}

/*
 * NEWS
 */
.news{
    @extend %blockpadding;
}
.news__inner{
    @extend %innerblock;
    @include mq(sm, min){
        display: flex;
    }
}
.news__title{
    margin-bottom: _rem(32.5px, sm);
    @include mq(sm, min){
     width: _rem(300px, md);
    }
     span{
        display: inline-block;
        padding: 0 1rem 1rem;
        border-bottom: 3px solid $color-black-three;
        @include mq(sm, min){
            padding: 0 1.5rem 1rem 0;
        }
     }
}
.news__block{
    flex: 1;
    height: 37rem;
    overflow: hidden;
    overflow-y: auto;
    @include mq(sm,min){
        margin-right: _rem(20px);
        height: 27rem;
        overflow: hidden;
        overflow-y: auto;
    }
}
.news__list{
}
.news__item{
    margin-bottom: _rem(20px, md);
    padding: 0 _rem(15px, sm);
    text-align: left;
    @include mq(sm,min){
        padding: 0;
    }

}
.item__label{
    display: inline-block;
    color: $color-white;
    padding: _rem(5px, sm);
    margin-bottom: 0.3rem;
    @include mq(sm, min){
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: _rem(200px, md);
    }
}
.item__label--all{
    background-color: $color-black-three;
}
.item__label--chohu{
    background-color: $color-aliceblue;
}
.item__label--shinurayasu{
    background-color: $color-mediumseagreen;
}
.item__inner{
    text-align: left;
    flex: 1;
    @extend %animation;
    @include mq(sm, min){
        margin: _rem(5px, md) 0;
    }
    &:hover{
        opacity: .6;
        transform: translateX(.5rem);
    }

}
.item__date{
    display: block;
    font-size: _rem(12px, sm);
    color: #7b7b7b;
    @include mq(sm, min){
        color: #6a6565;
        font-size: _rem(12px, md);
    }
}
.item__link{
    color: #6a6565;
    font-size: _rem(14px, sm);
    @include mq(sm, min){
        color: $color-black;
        font-size: 1rem;
    }
}
/*
 * CONCEPT
 */
.concept{
    @extend %blockpadding;
     background-color: $color-lite-gray;
}
.concept__title{
     span{
        padding-bottom: 1rem;
        display: inline-block;
        border-image: linear-gradient(to right, rgba($color-black-three, 0) 10%, rgba($color-black-three, 1) 10%, rgba($color-black-three, 1) 90%, rgba($color-black-three, 0) 90%) 1/0 0 3px 0;
        border-bottom: 3px solid;
     }
}
.concept__block{
    position: relative;
}
.concept__inner{
    @extend %innerblock;
    margin: _rem(80px, md) auto _rem(50px, md);
    display: flex;
    position: relative;
    z-index: 1;
    @include mq(sm, min){
        margin-bottom: _rem(110px, md);
    }
}
.concept__inner--left{
    padding-right: _rem(15px, sm);
    justify-content: flex-start;
}
.concept__inner--right{
    padding-left: _rem(15px, sm);
    justify-content: flex-end;
}
.concept__card{
    background-color: $color-white;
    text-align: left;
    transform: translate(0, _rem(30px, md));
    margin-top: _rem(175px, sm);
    padding: _rem(50px, sm) _rem(15px, md);
    @include mq(sm, min){
        margin-top: _rem(100px, md);
        padding: _rem(100px, md) _rem(60px, md);
        width: 53%;
    }
    @extend %animation;
    .started &{
        transform: translate(0, 0);
    }
}
.concept__image{
    position: absolute;
    top: 0;
    z-index: 0;
    @extend %animation;
    .started &{
        transform: translate(0, 0);
    }
    img{
        width: 100%;
    }
}
.concept__image--left{
    margin-right: _rem(15px, sm);
    left: 0;
    //transform: translate(_rem(-30px, md), 0);
    clip: rect(0, 0, 1000px, 0);
    .started & {
        clip: rect(0, 1000px, 1000px, 0);
    }
}
.concept__image--right{
    margin-left: _rem(15px, sm);
    right: 0;
    //transform: translate(_rem(30px, md), 0);
    clip: rect(0, 0, 1000px, 0);
    .started & {
        clip: rect(0, 1000px, 1000px, 0);
    }
}
.concept__subtitle{
    position: relative;
    margin-bottom: _rem(25px, sm);
    @include mq(sm, min){
        margin-bottom: _rem(65px, md);
    }
    span{
        font-size: _rem(18px, sm);
        position: relative;
        font-family: source-han-sans-japanese,sans-serif;
        @include mq(sm, min){
            font-size: _rem(30px, md);
        }
    }
}
.concept__subtitle--01:before,
.concept__subtitle--02:before{
    display: inline-block;
    font-size: 10rem;
    line-height: 1;
    color: #dadada;
    position: absolute;
    top: 50%;
    left: 2rem;
    z-index: 0;
    transform: translateY(-50%);
}
.concept__subtitle--01:before{
    content: '1';
}
.concept__subtitle--02:before{
    content: '2';
}
.concept__text{
    font-size: _rem(13px, sm);
    @include mq(sm, min){
        font-size: _rem(18px, md);
    }
}
/*
 * SHOP
 */
.shop{
    @extend %blockpadding;
    padding-bottom: 0;
     background-color: $color-black-two;
     color: $color-white;
}
.shop__title{
     span{
        display: inline-block;
        padding-bottom: 1rem;
        border-bottom: 3px solid $color-white;
        color: $color-white;
     }
}
.shop__block{
    margin-top: _rem(100px, md);
}
.shop__subtitle{
    font-size: _rem(30px, md);
    color: $color-white;
    margin-bottom: _rem(80px, md);
}
.shop__inner{
    padding: _rem(65px, sm) 0;
    background-size: cover;
    @include mq(sm, min){
        padding: _rem(130px, md) 0;
    }
}
.shop__inner--chohu{
    background-image: url(../img/top/shop-bg_01.jpg);
}
.shop__inner--shinurayasu{
    background-image: url(../img/top/shop-bg_02.jpg);
}
.shop__detail{
    text-align: left;
    margin: 0 _rem(15px, sm);
    dt,dd{
        color: #ccc;
    }
    dd{
        margin-bottom: 1rem;
        span{
            font-size: _rem(10px, sm);
        }
    }
    @include mq(sm, min){
        display: inline-block;
        margin: auto;
        dt,dd{
            float: left;
            color: $color-white;
            margin-top: _rem(35px,md);
        }
        dt{
            width: _rem(160px, md);
            clear: left;
        }
    }
}
.shop__note{
    margin-left: 1rem;
    color: $color-coral;
    &--long{
        @extend .shop__note;
        margin-left: 0;
    }
}
.shop__map{
    height: _rem(225px, sm);
    @include mq(sm, min){
        height: _rem(400px, md);
    }
}